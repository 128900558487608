export default [
  {
    path: '/report',
    name: 'reports',
    component: () => import('@/views/report/Reports.vue'),
    meta: {
      resource: 'report',
      action: 'all',
      pageTitle: 'Laporan User',
      // breadcrumb: [
      //   {
      //     text: 'Pesanan',
      //     active: true,
      //   },
      // ],
      contentClass: 'reports',
    },
    redirect: { name: 'ReportsOrder' },
    children: [
      {
        path: '/report-orders',
        name: 'ReportsOrder',
        component: () => import('@/views/report/ReportsOrder.vue'),
        meta: {
          resource: 'report',
          action: 'all',
          pageTitle: 'Laporan Pesanan',
          contentClass: 'reports',
          navActiveLink: 'reports',
        },
      },
      {
        path: '/report-product',
        name: 'ReportsProduct',
        component: () => import('@/views/report/ReportsProduct.vue'),
        meta: {
          resource: 'report',
          action: 'all',
          pageTitle: 'Laporan Produk',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Baru',
          //     active: true,
          //   },
          // ],
          contentClass: 'reports',
          navActiveLink: 'reports',
        },
      },
    ],
  },
  {
    path: '/report/:id',
    name: 'ReportsDetails',
    component: () => import('@/views/report/ReportsDetail.vue'),
    meta: {
      resource: 'report',
      action: 'all',
      pageTitle: 'Laporan',
      breadcrumb: [
        {
          text: 'Laporan',
          active: false,
          to: '/report',
        },
        {
          text: 'Detail Laporan',
          active: true,
        },
      ],
      navActiveLink: 'reports',
    },
  },
]
