import Vue from 'vue';
import VueRouter from 'vue-router';
import { canNavigate } from '@/libs/acl/routeProtection';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';

// import router
import noAuthentication from './noAuthentication';
import integration from './integration';
import subscription from './subscription';
import products from './products';
import orders from './orders';
import report from './report';
import accountSetting from './accountSetting';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...noAuthentication,
    ...integration,
    ...subscription,
    ...products,
    ...orders,
    ...report,
    ...accountSetting,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/v2/Dashboard.vue'),
      meta: {
        resource: 'dashboard',
        action: 'all',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' });

    // If logged in => not authorized
    return next({ name: 'unauthorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

export default router;
