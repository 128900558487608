import Swal from 'sweetalert2'
import Axios from '@axios'

export default {
  namespaced: 'marketplace',
  state: {
    tokopedia: [],
    lazada: [],
    shopee: [],
    shopList: [],
    all: {
      data: [],
      total: 0,
      perPage: 0,
    },
    allMarketplace: {
      data: [],
      total: 0,
      perPage: 0,
      shopList: [],
    },
  },
  mutations: {
    SET_TOKOPEDIA(state, value) {
      state.tokopedia = value
    },
    SET_LAZADA(state, value) {
      state.lazada = value
    },
    SET_SHOPEE(state, value) {
      state.shopee = value
    },
    SET_TIKTOK(state, data) {
      state.tiktok = data;
    },
    SET_BLIBLI(state, data) {
      state.blibli = data;
    },
    SET_LIST_TOKO_NAME(state, value) {
      state.shopList = value
    },
    SET_MARKETPLACE_DATA(state, payload) {
      state.allMarketplace.shopList = payload
      state.all.data = payload
    },
    SET_MARKETPLACE_PERPAGE(state, value) {
      state.all.perPage = value
    },
    SET_MARKETPLACE_TOTAL(state, payload) {
      state.all.total = payload
    },
  },
  actions: {
    async fetchMarketplace({ commit }, payload) {
      await Axios.get(`/marketplace?page=${payload.page || 1}&per_page=${payload.perPage || 10}`)
        .then(result => {
          const { data } = result.data
          commit(`SET_${payload.marketplace.toUpperCase()}`, data)
        }).catch(error => {
          console.log(error)
        })
    },

    async getAllMarketplace({ commit }, payload) {
      await Axios({
        url: `marketplace?page=${payload.page || 1}&per_page=${payload.perPage || 100}`,
        method: 'get',
      })
        .then(response => {
          const { data } = response.data

          commit('SET_MARKETPLACE_DATA', data)
          // commit('SET_MARKETPLACE_TOTAL', total)
          // commit('SET_MARKETPLACE_PERPAGE', per_page)

          // commit('SET_ALL_MARKETPLACE_SHOP_LIST', data)
          // commit('SET_ALL_MARKETPLACE_TOTAL', total_data)
          // commit('SET_ALL_MARKETPLACE_PERPAGE', total_data_per_page)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async deleteMarketplace(_, payload) {
      await Axios({
        url: `marketplace/${payload}`,
        method: 'DELETE',
      })
        .then(response => {
          if (response.data.message) {
            Swal.fire({
              title: 'Berhasil',
              icon: 'success',
              text: `${response.data.message || response.message || 'Integrasi Berhasil Dihapus'}`,
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 3000,
            })
          } else {
            Swal.fire({
              title: 'Marketplace Gagal dihapus',
              text: `${response.data.error || response.error || 'Galat Sistem-'}`,
              icon: 'error',
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              footer: 'Jangan tutup halaman ini',
              timer: 3000,
            })
          }
          window.location.reload()
        })
        .catch(err => {
          Swal.fire({
            title: 'Gagal',
            text: `${err.message || err}`,
            icon: 'error',
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            // footer: 'Jangan tutup halaman ini',
            timer: 3000,
          })
        })
    },

    async getStoreName({ commit }) {
      await Axios({
        url: '/ecm/tokped/shop-info/',
        method: 'get',
      })
        .then(result => {
          commit('SET_LIST_TOKO_NAME', result.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
