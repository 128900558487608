export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      pageTitle: 'Halaman tidak ditemukan',
      layout: 'full',
    },
  },
  // {
  //   path: '/auth',
  //   name: 'auth',
  //   // component: () => import('@/views/auth/Register.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     pageTitle: 'Masuk',
  //     redirectIfLoggedIn: true,
  //     action: 'read',
  //   },
  // },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      pageTitle: 'Masuk',
      redirectIfLoggedIn: true,
      action: 'read',
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      pageTitle: 'Masuk',
      redirectIfLoggedIn: true,
      action: 'read',
    },
  },
  {
    path: '/auth/register/account-activation',
    name: 'auth-activation',
    component: () => import('@/views/auth/AccountActivation.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      pageTitle: 'Aktivasi Akun',
      redirectIfLoggedIn: true,
      action: 'read',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/change-password',
    name: 'auth-change-password',
    component: () => import('@/views/auth/ChangeForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
