<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { provideToast } from 'vue-toastification/composition';
import { watch } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';
import axios from '@axios';

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');
const LayoutHorizontal = () =>
  import('@/layouts/horizontal/LayoutHorizontal.vue');
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');

const firebaseConfig = {
  apiKey: 'AIzaSyCMXg4u-2TPXlHUofswrvV9wnZgWbhextM',
  authDomain: 'kanaldagang-d4f88.firebaseapp.com',
  projectId: 'kanaldagang-d4f88',
  storageBucket: 'kanaldagang-d4f88.appspot.com',
  messagingSenderId: '1004522318059',
  appId: '1:1004522318059:web:445d10aa8522515512f6ae',
  measurementId: 'G-H51GGP1B2P',
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  data: () => ({
    fcmToken: '',
  }),
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  // watch route change then update fcm token
  watch: {
    $route(to) {
      if (to.name !== 'auth-login') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            if (this.fcmToken) {
              console.log('fcm token already exist');
              return;
            }

            // get token
            getToken(messaging, {
              vapidKey:
                'BNNINJcnGE7iG6pbwLPD94-QiWASMr_Mkb4viDVbU2Cjq5vduO-Je4VCa1ZXf7d0l8fEH0wD-6ICuuDnvpfCAvQ',
            })
              .then(async currentToken => {
                if (currentToken) {
                  this.fcmToken = currentToken;
                  await axios.post('/fcm', {
                    token: currentToken,
                    deviceType: window.navigator.userAgent,
                  });
                } else {
                  console.log(
                    'No registration token available. Request permission to generate one.',
                  );
                }
              })
              .catch(err => {
                console.log('An error occurred while retrieving token. ', err);
              });
          } else {
            this.$swal({
              title: 'Izin Notifikasi',
              text: 'Mohon izinkan notifikasi untuk mendapatkan informasi terbaru terkait pesanan',
              icon: 'info',
            });
          }
        });
      }
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  // async mounted() {
  //   await this.$messaging.getToken({
  //     vapidKey:
  //       'BNNINJcnGE7iG6pbwLPD94-QiWASMr_Mkb4viDVbU2Cjq5vduO-Je4VCa1ZXf7d0l8fEH0wD-6ICuuDnvpfCAvQ',
  //   });
  // },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
