import Axios from '@axios'

const URL_PATH = '/dashboard'

export default {
  namespaced: true,
  state: {
    sales: {
      totalSales: '',
      totalOrder: '',
    },
    topFiveProduct: [],
    topFiveCities: [],
    totalOrdersMarketplace: {},
    totalProducts: {},
  },
  mutations: {
    SET_TOTAL_SALES(state, payload) {
      state.sales.totalSales = payload
    },
    SET_TOTAL_ORDER(state, payload) {
      state.sales.totalOrder = payload
    },
    SET_TOP_FIVE_PRODUCT(state, payload) {
      state.topFiveProduct = payload
    },
    SET_TOP_FIVE_CITIES(state, payload) {
      state.topFiveCities = payload
    },
    SET_TOTAL_ORDER_MARKETPLACE(state, payload) {
      state.totalOrdersMarketplace = payload
    },
    SET_TOTAL_PRODUCTS(state, payload) {
      state.totalProducts = payload
    },
  },
  actions: {
    async fetchSalesToday({ commit }) {
      await Axios({
        url: `${URL_PATH}/salestoday`,
        method: 'GET',
      }).then(result => {
        const { data } = result.data
        commit('SET_TOTAL_SALES', data[0].total_sales_today)
        commit('SET_TOTAL_ORDER', data[0].total_order_today)
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchTopFiveProduct({ commit }) {
      await Axios({
        url: `${URL_PATH}/topfiveproducts`,
        method: 'GET',
      }).then(result => {
        const { data } = result.data
        commit('SET_TOP_FIVE_PRODUCT', data)
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchTopFiveCities({ commit }) {
      await Axios({
        url: `${URL_PATH}/topfivecities`,
        method: 'GET',
      }).then(result => {
        const { data } = result.data
        commit('SET_TOP_FIVE_CITIES', data)
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchTotalOrders({ commit }) {
      await Axios({
        url: `${URL_PATH}/count-orders`,
        method: 'GET',
      }).then(result => {
        if (result.data.data[0]) {
          const data = result.data.data[0]
          commit('SET_TOTAL_ORDER_MARKETPLACE', data)
          commit('SET_TOTAL_ORDER', data.all)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchTotalProducts({ commit }) {
      await Axios({
        url: `${URL_PATH}/count-products`,
        method: 'GET',
      }).then(result => {
        if (result.data.data) {
          const { data } = result.data
          commit('SET_TOTAL_PRODUCTS', data)
        }
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
