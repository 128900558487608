import crypto from 'crypto';
import Vue from 'vue';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';

const configs = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  Headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
};

const axiosIns = axios.create(configs);

axiosIns.interceptors.request.use(
  config => {
    const isLogin = config.url.includes('auth/login') && config.url.replace(config.baseURL, '').split('/')[0] === '';
    const isRegister = config.url.includes('auth/register');

    if (isLogin || isRegister) {
      delete config.headers.Authorization;
      config.headers['x-kd-auth'] = 1;

      if (isRegister) {
        config.url = config.url.replace('auth/register', 'users');
      }
    }

    // generate signature
    const clientKey = process.env.VUE_APP_CLIENT_KEY;
    const clientSecret = process.env.VUE_APP_CLIENT_SECRET;

    const path = () => {
      // check if url has query
      if (config.url.includes('?')) {
        return config.url.split('?')[0];
      }
      return config.url;
    };

    const method = config.method.toUpperCase();
    const timestamp = moment().unix();

    const signature = crypto.createHmac('sha256', clientSecret)
      .update(`${clientKey}${method}/api/${path()}${timestamp}${clientKey}`)
      .digest('hex')
      .toUpperCase();

    // assign to query named sign
    config.params = {
      ...config.params,
      sign: signature,
      timestamp,
    };

    // if url has showloading=0
    if (!config.url.includes('showloading=0')) {
      Swal.showLoading();
    }

    return config;
  },
  error => {
    Swal.close();
    return Promise.reject(error);
  },
);

// catch error from axios ans show to console
axiosIns.interceptors.response.use(
  response => {
    Swal.close();
    return response;
  },
  error => {
    Swal.close();
    // check url is login or not
    if (!error.config.url.includes('auth/login')) {
      if (error.response.status === 401) {
        Swal.fire({
          title: 'Oops...',
          text: 'Sesi Anda telah berakhir, silahkan login kembali',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          window.location.replace('/login');
        });
      }
    } else {
      Swal.fire({
        title: 'Oops...',
        text: error.response.data.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }

    if (error.response.status === 500 || error.response.status === 403 || error.response.status === 400) {
      if (error.response.data.message) {
        Swal.fire({
          title: 'Kesalahan',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Kesalahan',
          text: error.response.message || 'Terjadi kesalahan pada server',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  },
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
