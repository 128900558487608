export default [
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'dashboard',
      action: 'all',
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
]
