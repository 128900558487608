export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/v2/Order.vue'),
    meta: {
      resource: 'order',
      action: 'all',
      pageTitle: 'Pesanan',
      contentClass: 'orders',
    },
    redirect: { name: 'OrdersNew' },
    children: [
      {
        path: '/orders/new',
        name: 'OrdersNew',
        component: () => import('@/views/orders/v2/order-new/OrderNewList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          title: 'Pesanan Baru',
          // pageTitle: 'Pesanan Baru',
          contentClass: 'orders',
          navActiveLink: 'orders',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Baru',
          //     active: true,
          //   },
          // ],
        },
      },
      {
        path: '/orders/accepted',
        name: 'OrdersAccepted',
        component: () => import('@/views/orders/v2/order-accept/OrderAcceptedList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          contentClass: 'orders',
          title: 'Pesanan Diterima',
          navActiveLink: 'orders',
          // pageTitle: 'Diterima',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Diterima',
          //     active: true,
          //   },
          // ],
        },
      },
      {
        path: '/orders/ready-to-ship',
        name: 'OrdersReadyToShip',
        component: () => import('@/views/orders/v2/order-rts/OrderRTSList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          contentClass: 'orders',
          navActiveLink: 'orders',
          title: 'Pesanan Siap Kirim',
          // pageTitle: 'Pesanan Siap Kirim',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Siap Kirim',
          //     active: true,
          //   },
          // ],
        },
      },
      {
        path: '/orders/shipping',
        name: 'OrdersShipping',
        component: () => import('@/views/orders/v2/order-shipping/OrderShippingList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          contentClass: 'orders',
          navActiveLink: 'orders',
          title: 'Pesanan Dalam Pengiriman',
          // pageTitle: 'Pesanan Dalam Pengiriman',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Dalam Pengiriman',
          //     active: true,
          //   },
          // ],
        },
      },
      {
        path: '/orders/delivered',
        name: 'OrdersDelivered',
        component: () => import('@/views/orders/v2/order-delivered/OrderDeliveredList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          contentClass: 'orders',
          navActiveLink: 'orders',
          title: 'Pesanan Terkirim',
          // pageTitle: 'Pesanan Terkirim',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Terkirim',
          //     active: true,
          //   },
          // ],
        },
      },
      {
        path: '/orders/finished',
        name: 'OrdersFinished',
        component: () => import('@/views/orders/v2/order-finish/OrderFinishedList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          contentClass: 'orders',
          navActiveLink: 'orders',
          title: 'Pesanan Selesai',
          // pageTitle: 'Pesanan Selesai',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Selesai',
          //     active: true,
          //   },
          // ],
        },
      },
      {
        path: '/orders/canceled',
        name: 'OrdersCancelled',
        component: () => import('@/views/orders/v2/order-cancel/OrderCancelList.vue'),
        meta: {
          resource: 'order',
          action: 'all',
          contentClass: 'orders',
          navActiveLink: 'orders',
          title: 'Pesanan Dibatalkan',
          // pageTitle: 'Pesanan Dibatalkan',
          // breadcrumb: [
          //   {
          //     text: 'Pesanan Dibatalkan',
          //     active: true,
          //   },
          // ],
        },
      },
    ],
  },
  {
    path: '/orders/:id',
    name: 'orderDetails',
    component: () => import('@/views/orders/v1/OrderDetails.vue'),
    meta: {
      resource: 'order',
      action: 'all',
      pageTitle: 'Pesanan',
      title: 'Detail Pesanan',
      breadcrumb: [
        {
          text: 'Pesanan',
          active: false,
          to: '/orders',
        },
        {
          text: 'Detail Pesanan',
          active: true,
        },
      ],
      navActiveLink: 'orders',
    },
  },
];
