import Swal from 'sweetalert2'
import Axios from '@axios'

export default {
  namespaced: true,
  state: {
    requestTokopediaAll: {
      data: [],
      total: 0,
      perPage: 0,
    },
    category: [],
    product: {
      id: '',
      created_by: '',
      updated_by: '',
      category: null,
      name: '',
      description: '',
      sku: '',
      sell_price: null,
      modal_price: null,
      stock: null,
      min_quantity: null,
      max_quantity: null,
      length: null,
      width: null,
      height: null,
      image_cover: '',
    },
    allSyncedProduct: {
      data: [],
      total: 0,
      perPage: 0,
    },

    syncedDataAll: {
      data: [],
      total: 0,
      perPage: 0,
    },
    singleProduct: {},
    productListSearchBar: {
      selectedStatus: null,
      selectedShop: null,
      search: null,
    },
    orderId: null,
  },
  mutations: {
    SET_SHOP_REQUEST_TOKOPEDIA(state, payload) {
      state.requestTokopediaAll = payload
    },
    SET_CATEGORY(state, payload) {
      state.category = payload
    },
    SET_ALL_SYNCED_PRODUCT(state, payload) {
      state.allSyncedProduct = payload
    },
    SET_SYNCED_DATA_ALL(state, payload) {
      state.syncedDataAll.data = payload
    },
    SET_SYNCED_TOTAL(state, payload) {
      state.syncedDataAll.total = payload
    },
    SET_SYNCED_PERPAGE(state, payload) {
      state.syncedDataAll.perPage = payload
    },
    SET_PRODUCT_DETAIL(state, payload) {
      state.product = payload
    },
    SET_PRODUCT_NAME(state, payload) {
      state.product.name = payload
    },
    SET_PRODUCT_DESCRIPTION(state, payload) {
      state.product.description = payload
    },
    SET_PRODUCT_SKU(state, payload) {
      state.product.sku = payload
    },
    SET_PRODUCT_SELL_PRICE(state, payload) {
      state.product.sell_price = payload
    },
    SET_PRODUCT_MODAL_PRICE(state, payload) {
      state.product.modal_price = payload
    },
    SET_PRODUCT_STOCK(state, payload) {
      state.product.stock = payload
    },
    SET_PRODUCT_LENGTH(state, payload) {
      state.product.length = payload
    },
    SET_PRODUCT_WIDTH(state, payload) {
      state.product.width = payload
    },
    SET_PRODUCT_HEIGHT(state, payload) {
      state.product.height = payload
    },
    SET_PRODUCT_WEIGHT(state, payload) {
      state.product.weight = payload
    },
    SET_PRODUCT_PICTURES(state, payload) {
      state.product.pictures = payload
    },
    SET_PRODUCT_IMAGE_COVER(state, payload) {
      state.product.image_cover = payload
    },
    SET_SINGLE_PRODUCT(state, payload) {
      state.product.name = payload.name
      state.product.description = payload.description
      state.product.sku = payload.sku_seller
      state.product.sell_price = payload.price
      state.product.stock = payload.quantity
      state.product.length = payload.length
      state.product.width = payload.width
      state.product.height = payload.height
      state.product.weight = payload.weight
      state.product.pictures = [...payload.image]
      state.product.image_cover = payload.image
    },
    SET_PRODUCT_LIST_SEARCH_BAR_SELECTED_STATUS(state, payload) {
      state.productListSearchBar.selectedStatus = payload
    },
    SET_PRODUCT_LIST_SEARCH_BAR_SELECTED_SHOP(state, payload) {
      state.productListSearchBar.selectedShop = payload
    },
    SET_PRODUCT_LIST_SEARCH_BAR_SEARCH(state, payload) {
      state.productListSearchBar.search = payload
    },
    SET_ORDER_ID(state, payload) {
      state.orderId = payload
    },
  },
  //
  actions: {
    async fetchSyncedDataAll({ commit }, payload) {
      await Axios({
        url: 'tokopedia/product/sync',
        method: 'GET',
        params: {
          ns: false,
          ...payload,
        },
      })
        .then(result => {
          const {
            data, total, perPage,
          } = result.data.data
          data.forEach(item => {
            item.price = parseInt(item.product.price.value, 10)
            // const pict = []
            // // eslint-disable-next-line no-plusplus
            // for (let pic = 0; pic < 1; pic++) {
            //   pict.push(item.product.pictures.OriginalURL[pic])
            // }
            // item.pict = pict
          })
          // console.log(total)
          commit('SET_SYNCED_DATA_ALL', data)
          commit('SET_SYNCED_TOTAL', total)
          commit('SET_SYNCED_PERPAGE', perPage)
        })
    },
    async syncProduct({ dispatch }, payload) {
      await Axios({
        url: '/tokopedia/product/sync?ns=false',
        method: 'POST',
        params: {
          ...payload,
        },
      })
        .then(() => {
          setTimeout(() => {
            dispatch('fetchSyncedDataAll', {
              perPage: 10,
            })
          }, 5500)
        })
        .catch(error => {
          Swal.fire({
            title: 'Gagal',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonColor: '#2196f3',
            confirmButtonText: 'OK',
          })
        })
    },
    async setProductsDetail({ commit }, product) {
      try {
        await commit('SET_PRODUCT_DETAIL', product)
        return Promise.resolve() // Return a resolved promise on successful commit
      } catch (error) {
        return Promise.reject(error) // Return a rejected promise on error
      }
    },
    async requestShop(_, payload) {
      await Axios({
        url: 'marketplace/tokopedia',
        method: 'POST',
        data: payload,
      }).then(result => {
        // console.log(result)
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${result.data.message || result.message}`,
        })
      }).catch(error => {
        // console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${error.data.message || error.message}`,
        })
      })
    },
    async fetchSingleProduct({ commit }, payload) {
      await Axios({
        url: `tokopedia/product/single?access_token=${payload.access_token}&seller_sku=${payload.seller_sku}`,
        method: 'GET',
      }).then(result => {
        const product = result.data.data
        commit('SET_PRODUCT_SKU', product.skus[0].SellerSku)
        commit('SET_PRODUCT_NAME', product.attributes.name)
        commit('SET_PRODUCT_DESCRIPTION', product.attributes.description)
        commit('SET_PRODUCT_SELL_PRICE', product.skus[0].price)
        commit('SET_PRODUCT_STOCK', product.skus[0].quantity)
        // commit('SET_PRODUCT_MIN_QUANTITY', data.extraAttribute.minOrder)
        commit('SET_PRODUCT_HEIGHT', product.skus[0].package_width)
        commit('SET_PRODUCT_LENGTH', product.skus[0].package_length)
        commit('SET_PRODUCT_WEIGHT', product.skus[0].package_weight)
        commit('SET_PRODUCT_WIDTH', product.skus[0].package_width)
        const images = []
        product.images.forEach(item => {
          images.push(item)
        })
        commit('SET_PRODUCT_PICTURES', images)
        commit('SET_PRODUCT_IMAGE_COVER', product.images[0])
      }).catch(error => {
        console.log(error)
      })
    },
    // async detailProduct({ commit }, payload) {
    //   await Axios({
    //     url: `/tokopedia/product/${payload.id}`,
    //     method: 'GET',
    //   }).then(result => {
    //     commit('SET_PRODUCT_DETAIL', result.data.data)
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // },

    async getAllSyncProduct({ commit }, payload) {
      await Axios({
        url: 'tokopedia/product/sync',
        method: 'GET',
        params: {
          ns: false,
          ...payload,
        },
      })
        .then(result => {
          // console.log(result.data.data)
          const { data, total, perPage } = result.data.data

          commit('SET_ALL_SYNCED_PRODUCT', {
            data,
            total,
            perPage,
          })
        }).catch(error => {
          console.log(error)
        })
    },

    async getCategory({ commit }, payload) {
      await Axios({
        url: 'tokopedia/product/categories',
        method: 'GET',
        params: { ...payload },
      })
        .then(result => {
          commit('SET_CATEGORY', result.data.data.categories)
        })
        .catch(error => {
          console.log(error, '>> error')
        })
    },
    async postProduct({ commit }, payload) {
      await Axios({
        url: 'tokopedia/product/create',
        method: 'POST',
        data: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Produk Dikirim', 'success')
        })
    },
    async postDraftProduct(_, payload) {
      await Axios({
        url: 'tokopedia/product/draft?ns=false',
        method: 'POST',
        data: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Draft Produk Disimpan', 'success')
        })
    },
    async postPublishProduct(_, payload) {
      await Axios({
        url: 'tokopedia/product/publish?ns=false',
        method: 'POST',
        data: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Draft Produk Disimpan', 'success')
        })
    },

    async deleteProduct(_, payload) {
      await Axios({
        url: 'tokopedia/product',
        method: 'DELETE',
        params: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Produk Dihapus', 'success')
        })
    },

    async acceptOrder(_, payload) {
      await Axios({
        url: 'tokopedia/order/ack?ns=false',
        method: 'POST',
        params: {
          ...payload,
        },
      })
        .then(response => {
          Swal.fire(
            `${payload.orderId}`,
            'Pesanan berhasil diterima!',
            'success',
          )
        })
        .catch(error => {
          Swal.fire({
            title: 'Gagal',
            text: `${error.response.message}`,
            type: 'error',
            confirmButtonText: 'OK',
          })
        })
    },
    async refuseOrder(_, payload) {
      // const dataJSON = JSON.stringify(payload.data)
      await Axios({
        url: 'tokopedia/order/nack?ns=false',
        method: 'POST',
        params: {
          ...payload,
        },
      })
        .then(response => {
          Swal.fire({
            title: 'Berhasil',
            text: `${response.data.message}`,
            type: 'success',
            confirmButtonText: 'OK',
          })
        })
        .catch(error => {
          Swal.fire({
            title: 'Gagal',
            text: `${error.response.message}`,
            type: 'error',
            confirmButtonText: 'OK',
          })
        })
    },

    async requestPickup(_, payload) {
      // const dataJSON = JSON.stringify(newPayload)
      await Axios({
        url: 'tokopedia/order/request-pickup?ns=false',
        method: 'POST',
        params: {
          ...payload,
        },
      })
        .then(response => {
          Swal.fire({
            title: 'Success',
            text: `${response.data.data.result}`,
            type: 'success',
            confirmButtonText: 'OK',
          })
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: 'Request Pickup Failed',
            type: 'error',
            confirmButtonText: 'OK',
            footer: `${error.message}`,
          })
        })
    },

    async confirmShipping(_, payload) {
      // const dataJSON = JSON.stringify(newPayload)
      await Axios({
        url: 'tokopedia}/order/confirm-shipping?ns=false',
        method: 'POST',
        params: {
          ...payload,
        },
      })
        .then(response => {
          Swal.fire({
            title: 'Success',
            text: `${response.data.result}`,
            type: 'success',
            confirmButtonText: 'OK',
          })
        })
        .catch(error => {
          Swal.fire({
            title: 'Error',
            text: 'Confirm Shipping Failed',
            type: 'error',
            confirmButtonText: 'OK',
            footer: `${error.message}`,
          })
        })
    },
  },
}
