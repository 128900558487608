import Axios from '@axios'
import Swal from 'sweetalert2'
// import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    allPackage: [],
    allPayment: {
      data: [],
      total: 0,
      perPage: 0,
    },
    mdtToken: '',
  },
  mutations: {
    SET_ALL_PACKAGE(state, payload) {
      state.allPackage = payload
    },
    SET_MIDTRANS_TOKEN(state, payload) {
      state.mdtToken = payload
    },
    SET_ALL_PAYMENT(state, payload) {
      state.allPayment = payload
    },
  },
  actions: {
    async getPackagePriceByMonth({ commit }, payload) {
      await Axios({
        url: `users/members/get?page=1&per_page=20&month=${payload}`,
        method: 'GET',
      })
        .then(result => {
          commit('SET_ALL_PACKAGE', result.data)
        }).catch(error => {
          console.log(error)
        })
    },

    async getPaymentUrl({ commit }, payload) {
      await Axios({
        url: 'payment/create',
        method: 'POST',
        data: payload,
      })
        .then(result => {
          // const url = result.data.data.redirect_url
          const { token } = result.data.data
          commit('SET_MIDTRANS_TOKEN', token)
          // window.open(url, '_self')
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${error.data.message || error.message || 'Galat Sistem'}`,
          })
        })
    },

    async getAllPayment({ commit }) {
      await Axios({
        url: '/payment',
        method: 'GET',
      }).then(result => {
        const { data, total, perPage } = result.data
        commit('SET_ALL_PAYMENT', {
          data,
          total,
          perPage,
        })
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
