import Swal from 'sweetalert2'
import Axios from '@axios'

export default {
  namespaced: true,
  state: {
    activeFilter: 'Live',
    requestLazadaAll: {
      data: [],
      total: null,
      perPage: null,
    },
    syncedDataAll: {
      data: [],
      total: null,
      perPage: null,
    },
    loginFlag: false,
    category: [],
    singleProduct: {},
    productListSearchBar: {
      selectedStatus: null,
      selectedShop: null,
      search: null,
    },
  },
  mutations: {
    SET_SHOP_REQUEST_TOKOPEDIA(state, payload) {
      state.requestLazadaAll = payload
    },
    SET_SYNCED_DATA_ALL(state, payload) {
      state.syncedDataAll.data = payload
    },
    SET_SYNCED_TOTAL(state, payload) {
      state.syncedDataAll.total = payload
    },
    SET_SYNCED_PERPAGE(state, payload) {
      state.syncedDataAll.perPage = payload
    },
    SET_PRODUCT_DETAIL(state, payload) {
      state.product = payload
    },
    SET_PRODUCT_NAME(state, payload) {
      state.product.name = payload
    },
    SET_PRODUCT_DESCRIPTION(state, payload) {
      state.product.description = payload
    },
    SET_PRODUCT_SKU(state, payload) {
      state.product.sku = payload
    },
    SET_PRODUCT_SELL_PRICE(state, payload) {
      state.product.sell_price = payload
    },
    SET_PRODUCT_MODAL_PRICE(state, payload) {
      state.product.modal_price = payload
    },
    SET_PRODUCT_STOCK(state, payload) {
      state.product.stock = payload
    },
    SET_PRODUCT_LENGTH(state, payload) {
      state.product.length = payload
    },
    SET_PRODUCT_WIDTH(state, payload) {
      state.product.width = payload
    },
    SET_PRODUCT_HEIGHT(state, payload) {
      state.product.height = payload
    },
    SET_PRODUCT_WEIGHT(state, payload) {
      state.product.weight = payload
    },
    SET_PRODUCT_PICTURES(state, payload) {
      state.product.pictures = payload
    },
    SET_PRODUCT_IMAGE_COVER(state, payload) {
      state.product.image_cover = payload
    },
    SET_SINGLE_PRODUCT(state, payload) {
      state.product.name = payload.name
      state.product.description = payload.description
      state.product.sku = payload.sku_seller
      state.product.sell_price = payload.price
      state.product.stock = payload.quantity
      state.product.length = payload.length
      state.product.width = payload.width
      state.product.height = payload.height
      state.product.weight = payload.weight
      state.product.pictures = [...payload.image]
      state.product.image_cover = payload.image
    },
    SET_LOGIN_FLAG(state, payload) {
      state.loginFlag = payload
    },
    SET_PRODUCT_CATEGORY(state, payload) {
      state.category = payload
    },
    SET_PRODUCT_LIST_SEARCH_BAR_SELECTED_STATUS(state, payload) {
      state.productListSearchBar.selectedStatus = payload
    },
    SET_PRODUCT_LIST_SEARCH_BAR_SELECTED_SHOP(state, payload) {
      state.productListSearchBar.selectedShop = payload
    },
    SET_PRODUCT_LIST_SEARCH_BAR_SEARCH(state, payload) {
      state.productListSearchBar.search = payload
    },
  },
  //
  actions: {
    async getLogin() {
      await Axios({
        url: '/lazada/auth/login?ns=false',
        method: 'get',
      })
        .then(response => {
          const { url } = response.data.data

          window.open(url, '_self')
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getToken({ commit }, payload) {
      await Axios({
        url: `lazada/auth/access_token?ns=false&code=${payload.code}`,
        method: 'get',
      })
        .then(result => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${result.data.message || result.message}`,
          })
          commit('SET_LOGIN_FLAG', true)
        })
        .catch(error => {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${error.data.message || error.message || 'Kesalahan'}`,
          })
        })
    },
    async fetchSyncedDataAll({ commit }, payload) {
      await Axios({
        url: '/lazada/product/sync?ns=false',
        method: 'GET',
        params: {
          ...payload,
        },
      })
      // await Axios.get(`/lazada/product/sync?ns=false&page=${payload.page || 1}&per_page=${payload.perPage || 10}`)
        .then(result => {
          const {
            data, total, perPage,
          } = result.data.data
          commit('SET_SYNCED_DATA_ALL', data)
          commit('SET_SYNCED_TOTAL', total)
          commit('SET_SYNCED_PERPAGE', perPage)
        })
    },
    async syncProduct({ dispatch }, payload) {
      await Axios({
        url: '/lazada/product/sync?ns=false',
        method: 'POST',
        params: {
          ...payload,
        },
      })
        .then(() => {
          setTimeout(() => {
            dispatch('fetchSyncedDataAll', {
              per_page: 10,
            })
          }, 5500)
        })
        .catch(error => {
          Swal.fire({
            title: 'Gagal',
            text: `${error.response.data.message}`,
            icon: 'error',
            confirmButtonColor: '#2196f3',
            confirmButtonText: 'OK',
          })
        })
    },
    async requestShop(_, payload) {
      await Axios({
        url: 'marketplace/lazada',
        method: 'POST',
        data: payload,
      })
        .then(result => {
          // console.log(result)
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${result.data.message || result.message}`,
          })
        }).catch(error => {
          // console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${error.data.message || error.message}`,
          })
        })
    },
    async fetchSingleProduct({ commit }, payload) {
      await Axios({
        url: `lazada/product/single?access_token=${payload.access_token}&seller_sku=${payload.seller_sku}`,
        method: 'GET',
      }).then(result => {
        const product = result.data.data
        commit('SET_PRODUCT_SKU', product.skus[0].SellerSku)
        commit('SET_PRODUCT_NAME', product.attributes.name)
        commit('SET_PRODUCT_DESCRIPTION', product.attributes.description)
        commit('SET_PRODUCT_SELL_PRICE', product.skus[0].price)
        commit('SET_PRODUCT_STOCK', product.skus[0].quantity)
        // commit('SET_PRODUCT_MIN_QUANTITY', data.extraAttribute.minOrder)
        commit('SET_PRODUCT_HEIGHT', product.skus[0].package_width)
        commit('SET_PRODUCT_LENGTH', product.skus[0].package_length)
        commit('SET_PRODUCT_WEIGHT', product.skus[0].package_weight)
        commit('SET_PRODUCT_WIDTH', product.skus[0].package_width)
        const images = []
        product.images.forEach(item => {
          images.push(item)
        })
        commit('SET_PRODUCT_PICTURES', images)
        commit('SET_PRODUCT_IMAGE_COVER', product.images[0])
      }).catch(error => {
        console.log(error)
      })
    },
    async detailProduct({ commit }, payload) {
      await Axios({
        url: `/lazada/product/${payload.id}`,
        method: 'GET',
      }).then(result => {
        commit('SET_PRODUCT_DETAIL', result.data.data)
      }).catch(error => {
        console.log(error)
      })
    },
    async getCategory({ commit }, payload) {
      await Axios({
        url: 'lazada/product/category/suggest',
        method: 'GET',
        params: {
          ...payload,
        },
      })
        .then(result => {
          // console.log(result, 'result on get category')
          if (result.data.data.length > 0) {
            commit('SET_PRODUCT_CATEGORY', result.data.data)
          }
          if (result.data.length > 0) {
            commit('SET_PRODUCT_CATEGORY', result.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async postProduct({ commit }, payload) {
      await Axios({
        url: 'lazada/product/create',
        method: 'POST',
        data: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Produk Dikirim', 'success')
        })
    },
    async postDraftProduct(_, payload) {
      await Axios({
        url: 'lazada/product/draft?ns=false',
        method: 'POST',
        data: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Draft Produk Disimpan', 'success')
        })
    },
    async postPublishProduct(_, payload) {
      await Axios({
        url: 'lazada/product/publish?ns=false',
        method: 'POST',
        data: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Draft Produk Disimpan', 'success')
        })
    },

    async deleteProduct(_, payload) {
      await Axios({
        url: 'tokopedia/product',
        method: 'DELETE',
        params: {
          ...payload,
        },
      })
        .then(result => {
          Swal.fire('Produk Dihapus', 'success')
        })
    },
  },
}
