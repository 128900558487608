export default [
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/subscription/subscribe/Subscription.vue'),
    meta: {
      // pageTitle: 'Paket Langganan',
      resource: 'subscribe',
      action: 'all',
      // breadcrumb: [
      //   {
      //     text: 'Langganan',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/subscription/history',
    name: 'subcriptionHistory',
    component: () => import('@/views/subscription/history/History.vue'),
    meta: {
      pageTitle: 'History Langganan',
      resource: 'subscribe',
      action: 'all',
      breadcrumb: [
        {
          text: 'History',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '/subscription/history/detail/:id',
        name: 'subcriptionHistoryDetail',
        component: () => import('@/views/subscription/history/components/HistoryDetail.vue'),
        meta: {
          pageTitle: 'Detail History Langganan',
          resource: 'subscribe',
          action: 'all',
          breadcrumb: [
            {
              text: 'History',
              to: '/subscription/history',
            },
            {
              text: 'Detail',
              active: true,
            },
          ],
        },
      },
    ],
  },
];
