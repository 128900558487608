import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const baseUrl = process.env.VUE_APP_API_URL

const { jwt } = useJwt(axios)

jwt.jwtConfig.loginEndpoint = `${baseUrl}/auth/login`
jwt.jwtConfig.refreshEndpoint = `${baseUrl}/auth/refresh-token`

export default jwt
