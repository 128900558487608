import Swal from 'sweetalert2';
import axios from '@axios';
// import Axios from '@/api/axios'

// const URL_PATH = 'product'

export default {
  namespaced: true,
  state: {
    all: {
      data: [],
      total: 0,
      perPage: 0,
    },
    product: {},
    productFormData: {
      title: '',
      sku: '',
      description: '',
      price: '',
      stock: '',
      images: [],
    },
    product_statistics: {
      total_product: 0,
      total_sync: 0,
      total_modal: 0,
    },
    picture: '',
    picture_path: '',
    productSelected: {},
  },
  mutations: {
    SET_PRODUCTS_DATA(state, payload) {
      state.all.data = payload;
    },
    SET_PRODUCTS_PERPAGE(state, payload) {
      state.all.perPage = payload;
    },
    SET_PRODUCTS_TOTAL(state, payload) {
      state.all.total = payload;
    },
    SET_PRODUCTS_PICTURE(state, payload) {
      state.picture = payload;
    },
    SET_PRODUCT_DETAIL(state, payload) {
      state.product = payload;
    },
    SET_PRODUCTS_STATISTICS(state, payload) {
      state.product_statistics = payload;
    },
    SET_PRODUCTS_PICTURE_PATH(state, payload) {
      state.picture_path = payload;
    },
    setProductFormData(state, payload) {
      state.productFormData = payload;
    },
    SET_PRODUCT_SELECTED(state, payload) {
      state.productSelected = payload;
    },
  },
  actions: {
    async fetchProducts({ commit }, payload) {
      await axios.get(`/product?page=${payload.page || 1}&per_page=${payload.perPage || 10}&search=${payload.search || ''}`)
        .then(result => {
          const {
            data, total, per_page,
          } = result.data;

          data.forEach(item => {
            item.stock = parseInt(item.stock, 10);
            item.min_stock = parseInt(item.min_stock, 10);
            if (!Number(item.min_stock)) {
              item.min_stock = '-';
            }
            [item.image_cover] = item.images;
          });

          // commit the data to the store
          commit('SET_PRODUCTS_DATA', data);
          commit('SET_PRODUCTS_TOTAL', total);
          commit('SET_PRODUCTS_PERPAGE', per_page);
        }).catch(error => {
          console.log(error);
        });
    },

    // async postProductPicture({ commit }, payload) {
    //   await axios({
    //     url: 'product/image',
    //     method: 'POST',
    //     data: payload.file,
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   }).then(result => {
    //     commit('SET_PRODUCTS_PICTURE', result.data.image_url)
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // },

    async deleteProduct({ commit }, payload) {
      await axios({
        url: `product/${payload.id}`,
        method: 'DELETE',
      }).then(() => {
        Swal.fire(
          'Berhasil!',
          'Produk berhasil dihapus!',
          'success',
        );
      }).catch(error => {
        console.log(error);
        commit('SET_PRODUCTS_DELETE', false);
      });
    },

    async detailProduct({ commit }, payload) {
      await axios({
        url: `product/${payload.id}`,
        method: 'GET',
      }).then(result => {
        commit('SET_PRODUCT_DETAIL', result.data.data);
      }).catch(error => {
        console.log(error);
      });
    },

    async setProductsDetail({ commit }, product) {
      try {
        await commit('SET_PRODUCT_DETAIL', product);
        return Promise.resolve(); // Return a resolved promise on successful commit
      } catch (error) {
        return Promise.reject(error); // Return a rejected promise on error
      }
    },

    async fetchProductStatistics({ commit }) {
      await axios({
        url: 'product/statistics',
        method: 'GET',
      }).then(result => {
        commit('SET_PRODUCTS_STATISTICS', result.data.data);
      }).catch(error => {
        console.log(error);
      });
    },

    async updateProductSync(_, payload) {
      await axios({
        url: `product/sync/${payload.id}`,
        method: 'PUT',
      }).catch(error => {
        console.log(error);
      });
    },

    async updateProductStock(_, payload) {
      await axios({
        url: `product/update-stock/${payload.id}`,
        method: 'PUT',
        data: { stock: payload.stock },
      }).catch(error => {
        console.log(error);
      });
    },

    async updateProductPrice(_, payload) {
      await axios({
        url: `product/update-price/${payload.id}`,
        method: 'PUT',
        data: { price: payload.price },
      }).catch(error => {
        console.log(error);
      });
    },
    async setProductFormData({ commit }, formData) {
      try {
        const response = await axios.post('/product', formData);
        commit('setProductFormData', response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async postProduct(_, payload) {
      await axios({
        url: 'product',
        method: 'POST',
        data: payload,
      })
        .then(result => {
          if (result.data.sku) {
            Swal.fire({
              title: 'Success',
              text: 'Produk Berhasil Ditambahkan',
              type: 'success',
              confirmButtonText: 'OK',
            });
          }

          if (result.data.message) {
            Swal.fire({
              title: 'Error',
              text: result.message,
              type: 'error',
              confirmButtonText: 'OK',
            });
          }
        })
        .catch(error => {
          Swal.fire({
            title: 'Gagal',
            text: `${error.data.message}` || `${error.data.message}` || 'Produk Gagal Ditambahkan',
            type: 'error',
            confirmButtonText: 'OK',
          });
        });
    },
    async updateProductMaster(_, payload) {
      await axios({
        url: `product/${payload.id}`,
        method: 'PUT',
        data: payload,
      }).catch(error => {
        console.log(error);
      });
    },
    async migrateImage({ commit }, payload) {
      await axios({
        url: 'product/image/local',
        method: 'POST',
        data: payload,
      }).then(result => {
        commit('SET_PRODUCTS_PICTURE_PATH', result.data.path);
        commit('SET_PRODUCTS_PICTURE', result.data.url);
      }).catch(error => {
        console.log(error);
      });
    },

    async syncStock(_, payload) {
      await axios({
        url: 'product/sync-stock',
        method: 'POST',
        data: payload,
      }).catch(error => {
        console.log(error);
      });
    },

    async syncPrice(_, payload) {
      await axios({
        url: 'product/sync-price',
        method: 'POST',
        data: payload,
      }).catch(error => {
        console.log(error);
      });
    },

    async setProductIds({ commit }, payload) {
      commit('SET_PRODUCT_SELECTED', payload);
    },
  },
};
