import Swal from 'sweetalert2'
import Axios from '@axios'

export default {
  namespaced: true,
  state: {
    activeFilter: 'Live',
    requestTiktokAll: {
      data: [],
      total: 0,
      perPage: 0,
    },
    syncedDataAll: {
      data: [],
      total: 0,
      perPage: 0,
    },
  },
  mutations: {
    SET_SHOP_REQUEST_TIKTOK(state, payload) {
      state.requestTiktokAll = payload
    },
    SET_SYNCED_DATA_ALL(state, payload) {
      state.syncedDataAll.data = payload
    },
    SET_SYNCED_TOTAL(state, payload) {
      state.allTotal = payload
    },
    SET_SYNCED_PERPAGE(state, payload) {
      state.perPage = payload
    },
    SET_PRODUCT_DETAIL(state, payload) {
      state.product = payload
    },
    SET_PRODUCT_NAME(state, payload) {
      state.product.name = payload
    },
    SET_PRODUCT_DESCRIPTION(state, payload) {
      state.product.description = payload
    },
    SET_PRODUCT_SKU(state, payload) {
      state.product.sku = payload
    },
    SET_PRODUCT_SELL_PRICE(state, payload) {
      state.product.sell_price = payload
    },
    SET_PRODUCT_MODAL_PRICE(state, payload) {
      state.product.modal_price = payload
    },
    SET_PRODUCT_STOCK(state, payload) {
      state.product.stock = payload
    },
    SET_PRODUCT_LENGTH(state, payload) {
      state.product.length = payload
    },
    SET_PRODUCT_WIDTH(state, payload) {
      state.product.width = payload
    },
    SET_PRODUCT_HEIGHT(state, payload) {
      state.product.height = payload
    },
    SET_PRODUCT_WEIGHT(state, payload) {
      state.product.weight = payload
    },
    SET_PRODUCT_PICTURES(state, payload) {
      state.product.pictures = payload
    },
    SET_PRODUCT_IMAGE_COVER(state, payload) {
      state.product.image_cover = payload
    },
    SET_SINGLE_PRODUCT(state, payload) {
      state.product.name = payload.name
      state.product.description = payload.description
      state.product.sku = payload.sku_seller
      state.product.sell_price = payload.price
      state.product.stock = payload.quantity
      state.product.length = payload.length
      state.product.width = payload.width
      state.product.height = payload.height
      state.product.weight = payload.weight
      state.product.pictures = [...payload.image]
      state.product.image_cover = payload.image
    },
  },
  //
  actions: {
    async fetchSyncedDataAll({ commit }, payload) {
      await Axios.get(`/tiktok/product/sync?ns=false&page=${payload.page || 1}&per_page=${payload.perPage || 10}`)
        .then(result => {
          const {
            data, total, perPage,
          } = result.data.data
          // data.forEach(item => {
          //   // item.price = parseInt(item.product.price.value, 10)
          //   // const pict = []
          //   // // eslint-disable-next-line no-plusplus
          //   // for (let pic = 0; pic < 1; pic++) {
          //   //   pict.push(item.product.pictures.OriginalURL[pic])
          //   // }
          //   // item.pict = pict
          // })
          // console.log('tiktokdata', data)
          commit('SET_SYNCED_DATA_ALL', data)
          commit('SET_SYNCED_TOTAL', total)
          commit('SET_SYNCED_PERPAGE', perPage)
        })
    },
    async requestShop(_, payload) {
      await Axios({
        url: 'marketplace/tiktok',
        method: 'POST',
        data: payload,
      })
        .then(result => {
          // console.log(result)
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${result.data.message || result.message}`,
          })
        }).catch(error => {
          // console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${error.data.message || error.message}`,
          })
        })
    },
    async fetchSingleProduct({ commit }, payload) {
      await Axios({
        url: `tiktok/product/single?access_token=${payload.access_token}&seller_sku=${payload.seller_sku}`,
        method: 'GET',
      }).then(result => {
        const product = result.data.data
        commit('SET_PRODUCT_SKU', product.skus[0].SellerSku)
        commit('SET_PRODUCT_NAME', product.attributes.name)
        commit('SET_PRODUCT_DESCRIPTION', product.attributes.description)
        commit('SET_PRODUCT_SELL_PRICE', product.skus[0].price)
        commit('SET_PRODUCT_STOCK', product.skus[0].quantity)
        // commit('SET_PRODUCT_MIN_QUANTITY', data.extraAttribute.minOrder)
        commit('SET_PRODUCT_HEIGHT', product.skus[0].package_width)
        commit('SET_PRODUCT_LENGTH', product.skus[0].package_length)
        commit('SET_PRODUCT_WEIGHT', product.skus[0].package_weight)
        commit('SET_PRODUCT_WIDTH', product.skus[0].package_width)
        const images = []
        product.images.forEach(item => {
          images.push(item)
        })
        commit('SET_PRODUCT_PICTURES', images)
        commit('SET_PRODUCT_IMAGE_COVER', product.images[0])
      }).catch(error => {
        console.log(error)
      })
    },
    async detailProduct({ commit }, payload) {
      await Axios({
        url: `/tiktok/product/${payload.id}`,
        method: 'GET',
      }).then(result => {
        commit('SET_PRODUCT_DETAIL', result.data.data)
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
