import Axios from 'axios'
import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    user: {},
  },
  mutations: {
  },
  actions: {
    async activationUser(_, payload) {
      await Axios({
        url: `${process.env.VUE_APP_API_URL}/auth/activated`,
        method: 'PUT',
        params: {
          activationCode: payload.code,
        },
        headers: {
          'x-kd-auth': 1,
        },
      }).then(result => {
        setTimeout(() => {
          Swal.fire({
            title: 'Aktivasi Akun',
            text: `${result.data.message}`,
            icon: (result.data.status ? 'success' : 'error'),
            confirmButtonText: 'OK',
          }).then(() => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            window.location.replace('/auth/login')
          })
        }, 3000)
      }).catch(error => {
        Swal.fire({
          title: 'Aktivasi Akun',
          text: `${error.response.data.message}`,
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          window.location.replace('/auth/login')
        })
      })
    },

    async forgotPassword(_, payload) {
      await Axios({
        url: `${process.env.VUE_APP_API_URL}/auth/forgot-password`,
        method: 'POST',
        data: payload,
        headers: {
          'x-kd-auth': 1,
        },
      }).then(result => {
        Swal.fire({
          title: 'Lupa Password',
          text: `${result.data.message}`,
          icon: (result.data.status ? 'success' : 'error'),
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.replace('/auth/login')
        })
      })
    },

    async changePassword(_, payload) {
      await Axios({
        url: `${process.env.VUE_APP_API_URL}/auth/change-password?code=${(payload.code || null)}`,
        method: 'POST',
        data: {
          ...payload.data,
        },
        headers: {
          'x-kd-auth': 1,
        },
      }).then(result => {
        Swal.fire({
          title: 'Ganti Password',
          text: `${result.data.message}`,
          icon: (result.data.status ? 'success' : 'error'),
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.replace('/auth/login')
        })
      })
    },
  },
}
