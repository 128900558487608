import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueMeta from 'vue-meta';
import VueCompositionAPI from '@vue/composition-api';
import VueSweetalert2 from 'vue-sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  required, email, numeric, between, mimes,
} from 'vee-validate/dist/rules';
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate';

// Font Awesome free icons all
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import router from './router';
import store from './store';
import App from './App.vue';
import './registerServiceWorker';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/acl';
import 'sweetalert2/dist/sweetalert2.min.css';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueSweetalert2);

// Composition API
Vue.use(VueCompositionAPI);

// Meta Tags
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

require('@core/assets/fonts/feather/iconfont.css');

Vue.config.productionTip = false;

extend('required', required);
extend('email', email);
extend('numeric', numeric);
extend('between', between);
extend('mimes', mimes);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Font Awesome
library.add(far, fab, fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Vue.prototype.$iconShopee = require('@/assets/images/marketplace/shopee.png')
// eslint-disable-next-line import/no-dynamic-require, global-require
Vue.prototype.$iconMarketplace = name => require(`@/assets/images/marketplace/${name}.png`);

// this is test comment to test github action, TODO remove in the future :)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
