// import store from '@/store'

export default [
  {
    path: '/products/master',
    name: 'productsMaster',
    component: () => import('@/views/products/v2/master/ProductMasterList.vue'),
    meta: {
      resource: 'product',
      action: 'all',
      pageTitle: 'Produk Utama',
      breadcrumb: [
        {
          text: 'Produk Utama',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products',
    name: 'productsMarketplace',
    component: () => import('@/views/products/v2/marketplace/MarketplaceProductList.vue'),
    redirect: { name: 'ProductsTokopedia' },
    meta: {
      resource: 'product',
      action: 'all',
      pageTitle: 'Produk Toko (Marketplace)',
      contentClass: 'productsMarketplace',
    },
    children: [
      {
        path: 'tokopedia',
        name: 'ProductsTokopedia',
        component: () => import('@/views/products/v2/marketplace/MarketplaceTokopediaList.vue'),
        meta: {
          resource: 'product',
          action: 'all',
          pageTitle: 'Produk - Tokopedia',
          breadcrumb: [
            {
              text: 'Tokopedia',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tokopedia/add',
        name: 'FormProductTokopedia',
        component: () => import('@/views/products/v2/marketplace/FormTokopedia.vue'),
        meta: {
          resource: 'product',
          action: 'all',
          pageTitle: 'Tambah Produk - Tokopedia',
          breadcrumb: [
            {
              text: 'Tokopedia',
              to: '/products/tokopedia',
            },
            {
              text: 'Tambah Produk',
              active: true,
            },
          ],
        },
      },
      {
        path: 'shopee',
        name: 'ProductsShopee',
        component: () => import('@/views/products/v2/marketplace/MarketplaceShopeeList.vue'),
        meta: {
          resource: 'product',
          action: 'all',
          pageTitle: 'Produk - Shopee',
          breadcrumb: [
            {
              text: 'Shopee',
              active: true,
            },
          ],
        },
      },
      {
        path: 'lazada',
        name: 'ProductsLazada',
        component: () => import('@/views/products/v2/marketplace/MarketplaceLazadaList.vue'),
        meta: {
          resource: 'product',
          action: 'all',
          pageTitle: 'Produk - Lazada',
          breadcrumb: [
            {
              text: 'Lazada',
              active: true,
            },
          ],
        },
      },
      {
        path: 'tiktok',
        name: 'ProductsTiktok',
        component: () => import('@/views/products/v2/marketplace/MarketplaceTiktokList.vue'),
        meta: {
          resource: 'product',
          action: 'all',
          pageTitle: 'Produk - Tiktok',
        },
      },
    ],
  },
  {
    path: '/products/master/add',
    name: 'productMasterForm',
    component: () => import('@/views/products/v2/master/ProductFormMaster.vue'),
    meta: {
      resource: 'product',
      action: 'all',
      pageTitle: 'Tambah Produk',
      contentClass: 'productsMaster',
      navActiveLink: 'productsMaster',
      breadcrumb: [
        {
          text: 'Produk Utama',
          to: '/products/master',
        },
        {
          text: 'Tambah Produk',
          active: true,
        },
      ],
    },
  },

  {
    path: '/products/master/:id/edit',
    name: 'productMasterEdit',
    component: () => import('@/views/products/v2/master/ProductFormMaster.vue'),
    meta: {
      resource: 'product',
      action: 'all',
      pageTitle: 'Ubah Produk',
      contentClass: 'productsMaster',
      navActiveLink: 'productsMaster',
      breadcrumb: [
        {
          text: 'Produk Utama',
          to: '/products/master',
        },
        {
          text: 'Ubah Produk',
          active: true,
        },
      ],
    },
  },

  {
    path: '/products/master/copy',
    name: 'productMasterCopy',
    component: () => import('@/views/products/v2/copy-product-master/ProductCopyForm.vue'),
    meta: {
      resource: 'product',
      action: 'all',
      pageTitle: 'Salin Produk',
      contentClass: 'productsMaster',
      navActiveLink: 'productsMaster',
      breadcrumb: [
        {
          text: 'Produk Utama',
          to: '/products/master',
        },
        {
          text: 'Salin Produk',
          active: true,
        },
      ],
    },
  },
];
