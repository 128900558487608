/* eslint-disable camelcase */
import moment from 'moment'
// import Swal from 'sweetalert2'
import axios from '@axios'

const URL_PATH = '/order'

export default {
  namespaced: 'orders',
  state: {
    all: {
      data: [],
      total: 0,
      perPage: 0,
    },
    order: {},
    filterData: {
      search: null,
      shopId: null,
      dateRange: null,
      route: null,
      sortBy: null,
    },
  },
  getters: {
    getOrdersByMarketplace: state => marketplace => state.all.data.find(order =>
      // console.log('get By Markteplace')
      order.marketplaceName === marketplace),

    getOrdersByStatus: state => status => state.all.data.filter(order =>
      // console.log('get By Status')
      order.orderStatusInternal === status),
  },
  mutations: {
    SET_ORDER_DATA(state, payload) {
      state.all.data = payload
    },
    SET_PRODUCTS_PERPAGE(state, value) {
      state.all.perPage = value
    },
    SET_ORDER_TOTAL(state, payload) {
      state.all.total = payload
    },
    SET_SINGLE_ORDER_DATA(state, payload) {
      state.order = payload
    },
    SET_FILTER_DATA(state, payload) {
      // console.log(payload, '>> payload on store')
      state.filterData = payload
    },
  },
  actions: {
    async fetchOrders({ commit }, payload) {
      await axios({
        url: `${URL_PATH}`,
        method: 'GET',
        params: {
          ...payload,
        },
      })
        .then(result => {
          // console.log(result, '>> result')
          const { total } = result.data
          const { perPage } = result.data
          const { data } = result.data
          data.forEach(item => {
            // item.updated_at = moment(item.updated_at).format('DD MMM YYYY HH:mm')
            switch (item.marketplaceType) {
              case 'tokopedia':
                item.orderIdShop = `TKPD-${item.orderId}`
                break
              case 'shopee':
                item.orderIdShop = `SHPE-${item.orderId}`
                break
              case 'blibli':
                item.orderIdShop = `BLBL-${item.orderId}`
                break
              case 'lazada':
                item.orderIdShop = `LZDA-${item.orderId}`
                break
              case 'tiktok':
                item.orderIdShop = `TKOK-${item.orderId}`
                break
              default:
                item.orderIdShop = `${item.orderId}`
                break
            }
            item.createdDate = moment(item.createdDate).format('DD MMM YYYY HH:mm')
            // delete four string from the end item.createdAt
            // item.createdAt = item.createdAt.slice(0, -4)
            return item
          })
          commit('SET_ORDER_DATA', data)
          commit('SET_ORDER_TOTAL', total)
          commit('SET_PRODUCTS_PERPAGE', perPage)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getSingleOrder(context, payload) {
      await axios({
        url: `${URL_PATH}/${payload}`,
        method: 'GET',
      })
        .then(response => {
          const { data } = response.data

          data.updated_at = moment(data.updated_at).format('DD MMM YYYY HH:mm')
          context.commit('SET_SINGLE_ORDER_DATA', data)
        })
        .catch(error => {
          console.log(error)
        })
    },

    async setOrderDetail({ commit }, product) {
      try {
        await commit('SET_SINGLE_ORDER_DATA', product)
        return Promise.resolve() // Return a resolved promise on successful commit
      } catch (error) {
        return Promise.reject(error) // Return a rejected promise on error
      }
    },
  },
}
