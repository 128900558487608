import Swal from 'sweetalert2'
import Axios from '@axios'

export default {
  namespaced: true,
  state: {
    user: {},
    picture: '',
    isMustChangePassword: false,
  },
  mutations: {
    SET_USER_DATA(state, payload) {
      state.user = payload
    },
  },
  actions: {
    async refreshToken(_, payload) {
      await Axios({
        url: 'refresh',
        method: 'POST',
        data: payload,
      }).then(result => {
        const { data } = result
        localStorage.setItem('accessToken', data.token)
      }).catch(error => {
        console.log(error)
      })
    },

    async getUserProfile({ commit }) {
      await Axios({
        url: 'users/me',
        method: 'GET',
      }).then(result => {
        const userData = result.data
        commit('SET_USER_DATA', userData)
      }).catch(error => {
        console.log(error)
      })
    },

    async editUserProfile({ commit, dispatch }, payload) {
      await Axios({
        url: 'users',
        method: 'PUT',
        data: payload,
      }).then(async () => {
        const refreshToken = {
          refreshToken: localStorage.getItem('refreshToken'),
        }
        await Axios({
          url: 'auth/refresh',
          method: 'POST',
          data: refreshToken,
        }).then(result => {
          const { data } = result
          localStorage.setItem('accessToken', data.token)
          Swal.fire({
            title: 'Sukses',
            text: 'Perubahan Berhasil di Simpan',
            icon: 'success',
            confirmButtonColor: '#2196f3',
            confirmButtonText: 'OK',
          })
          // window.location.reload()
        })
      })
    },
  },
}
