export default [
  {
    path: '/integration',
    name: 'integration',
    component: () => import('@/views/integration/v2/IntegrationList.vue'),
    meta: {
      // pageTitle: 'Integrasi Toko',
      resource: 'settings',
      action: 'all',
      // breadcrumb: [
      //   {
      //     text: 'Integrasi',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/integration/shopee',
    name: 'proceedShopee',
    component: () => import('@/views/integration/components/redirect/RedirectPageShopee.vue'),
    meta: {
      pageTitle: 'Proses Login',
      resource: 'settings',
      layout: 'full',
      action: 'all',
    },
  },
  {
    path: '/integration/lazada',
    name: 'proceedLazada',
    component: () => import('@/views/integration/components/redirect/RedirectPageLazada.vue'),
    meta: {
      pageTitle: 'Proses Login',
      resource: 'settings',
      layout: 'full',
      action: 'all',
    },
  },
];
