import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import marketplace from './marketplace'
import shopee from './shopee'
import lazada from './lazada'
import tiktok from './tiktok'
import tokopedia from './tokopedia'
import subscribe from './subscribe'
import user from './user'
import master from './master'
import auth from './authentication'
import orders from './orders'
import dashboard from './dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    marketplace,
    shopee,
    lazada,
    tiktok,
    tokopedia,
    subscribe,
    user,
    master,
    auth,
    orders,
    dashboard,
  },
  strict: process.env.DEV,
})
